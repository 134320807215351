import { isAxiosError } from 'axios';
import React, { Component, ErrorInfo, ReactNode } from 'react';

import MainLayout from '@/components/layouts/MainLayout';

import ErrorContent from './ErrorContent';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
}

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Log error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      const error = this.state.error;
      const status = isAxiosError(error) ? error.response?.status : undefined;

      return (
        <MainLayout backgroundColor='dark'>
          <ErrorContent status={status} />
        </MainLayout>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
