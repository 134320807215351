import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import React, { createContext, PropsWithChildren, useContext } from 'react';

export type ThemeContextType = {
  bSource: 'hde' | 'hrs';
};

const Context = createContext<ThemeContextType>({
  bSource: 'hrs',
});

export const ThemeContext = ({ children }: PropsWithChildren) => {
  const { isReady } = useRouter();
  const searchParams = useSearchParams();

  const bSource = (searchParams.get('bSource') ?? 'hrs') as ThemeContextType['bSource'];

  return (
    <Context.Provider
      value={{
        bSource,
      }}
    >
      {isReady && <div data-theme={bSource === 'hde' ? 'hotelDE' : 'HRS'}>{children}</div>}
    </Context.Provider>
  );
};

export function useThemeContext() {
  return useContext<ThemeContextType>(Context);
}
