import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';

import Loading from '@/components/bases/Loading';
import { CheckinFormDataType } from '@/components/interfaces/CheckinForm/type';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useGetCheckinData } from '@/hooks/useQuery/useGetCheckinData';
import { GetCheckinResponse, TextComponents } from '@/services/generated';

type TextComponentsType = {
  de?: TextComponents;
  en?: TextComponents;
};

export type ContextType = {
  checkinInfo?: GetCheckinResponse;
  language: string;
  secureToken: string;
  isPostCheckedIn: boolean;
  setCheckinInfo: React.Dispatch<React.SetStateAction<GetCheckinResponse | undefined>>;

  textComponents: TextComponentsType;
  setTextComponents: React.Dispatch<React.SetStateAction<TextComponentsType>>;
};

export const Context = createContext<ContextType>({
  language: '',
  secureToken: '',
  isPostCheckedIn: false,
  setCheckinInfo: () => {},

  textComponents: {},
  setTextComponents: () => {},
});

export const AppContext = ({ children }: PropsWithChildren) => {
  const [checkinInfo, setCheckinInfo] = useState<GetCheckinResponse>();
  const [textComponents, setTextComponents] = useState<TextComponentsType>({});
  const [isPostCheckedIn, setIsPostCheckedIn] = useState(false);
  const { push, isReady } = useRouter();
  const { i18n } = useTranslation();
  const searchParams = useSearchParams();

  const [tokenStorage, setTokenStorage] = useLocalStorage('btc_checkin_token');
  const [_, setCheckinFormDataLocalStorage] = useLocalStorage<CheckinFormDataType | undefined>('btc_checkin_form_data');

  const language = searchParams.get('language') ?? '';
  const secureToken = searchParams.get('secureToken') ?? '';
  const bSource = searchParams.get('bSource') ?? 'hrs';

  const { data: checkinDataDefault, isLoading } = useGetCheckinData({
    language,
    secureToken,
  });

  useEffect(() => {
    if (checkinDataDefault) {
      setCheckinInfo(checkinDataDefault);

      const textComponent = checkinDataDefault.checkinConfig.textComponents;
      const key = language === 'eng' ? 'en' : 'de';

      setTextComponents((prevTextComponents) => ({
        ...prevTextComponents,
        [key]: textComponent,
      }));

      if (checkinDataDefault.booking.checkinPass) {
        setIsPostCheckedIn(true);
        push({
          pathname: '/confirmation',
          query: { language, secureToken, bSource },
        });
      }
    }
    //deep compare objects
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(checkinDataDefault), language]);

  useEffect(() => {
    if (tokenStorage !== secureToken && isReady) {
      setTokenStorage(secureToken);

      setCheckinFormDataLocalStorage(undefined);
    }
  }, [secureToken, setCheckinFormDataLocalStorage, setTokenStorage, tokenStorage, isReady]);

  useEffect(() => {
    if (language === 'eng') {
      document.documentElement.setAttribute('lang', 'en');
      i18n.changeLanguage('en');
    }
  }, [i18n, language]);

  return (
    <Context.Provider
      value={{
        checkinInfo,
        language,
        secureToken,
        setCheckinInfo,
        isPostCheckedIn,
        textComponents,
        setTextComponents,
      }}
    >
      <Loading isLoading={isLoading} />
      {children}
    </Context.Provider>
  );
};

export function useAppContext() {
  return useContext<ContextType>(Context);
}
