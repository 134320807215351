import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

import Footer from '@/components/bases/Footer';
import Navbar from '@/components/bases/Navbar';
import { FooterContext } from '@/contexts/FooterContext';

interface MainLayoutProps extends PropsWithChildren {
  backgroundColor?: 'dark' | 'light';
}
const MainLayout = function ({ backgroundColor = 'light', children }: MainLayoutProps) {
  const classesWapper = twMerge(
    'min-h-screen',
    backgroundColor === 'light' ? 'mobile:bg-primary desktop:bg-gray-75' : 'bg-secondary',
  );
  const classes = twMerge(
    'desktop:min-h-min mobile:min-h-[calc(100vh-5rem-54px)] desktop:mb-[78px] w-full px-3 desktop:pb-[78px] mobile:pt-6 desktop:pt-[88px] mobile:rounded-t-2xl desktop:rounded-none',
    backgroundColor === 'light' ? 'bg-gray-75 ' : 'bg-secondary',
  );

  return (
    <div className={classesWapper}>
      <FooterContext>
        <Navbar />
        <main data-testid='mainContainer' className={classes}>
          <div className='m-auto mobile:max-w-[343px] tablet:max-w-[343px] desktop:max-w-[1216px]'>{children}</div>
        </main>
        <Footer />
      </FooterContext>
    </div>
  );
};

export default MainLayout;
