import '../styles/globals.css';

import type { NextPage } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { ReactElement, ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import ErrorBoundary from '@/components/interfaces/Errors/errorBoundary';
import { AppContext } from '@/contexts/AppContext';
import { ThemeContext } from '@/contexts/ThemeContext';

import i18nextConfig from '../../next-i18next.config';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const queryClient = new QueryClient();

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout = Component.getLayout || ((page) => page);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeContext>
        <ErrorBoundary>
          <AppContext>
            {getLayout(
              <>
                <Head>
                  <meta name='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=no' />
                </Head>
                <Component {...pageProps} />
              </>,
            )}
          </AppContext>
        </ErrorBoundary>
      </ThemeContext>
    </QueryClientProvider>
  );
};

export default appWithTranslation(MyApp, i18nextConfig);
