import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@/components/bases/Typography';
import { useFooterContext } from '@/contexts/FooterContext';

const PoliciesAndPrivacy = () => {
  const { t } = useTranslation();
  const { openTnC, openPP, openLNOH } = useFooterContext();

  return (
    <Typography variant='paragraph' className='self-center mobile:text-xs desktop:text-sm'>
      {t('forms_signature_accept')}
      <span> </span>
      <span className='underline cursor-pointer' onClick={openLNOH}>{`${t('forms_signature_policies_notice')}`}</span>
      <span>{`, `}</span>
      <span className='underline cursor-pointer' onClick={openTnC}>{`${t('forms_signature_policies_terms')}`}</span>
      <span>{` ${t('and')} `}</span>
      <span className='underline cursor-pointer' onClick={openPP}>{`${t('forms_signature_policies_privacy')}`}</span>
      <span>{` ${t('of_hrs')}.`}</span>
    </Typography>
  );
};

export default PoliciesAndPrivacy;
