import classNames from 'classnames';
import React, { ButtonHTMLAttributes, ForwardedRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { variants } from './config';
import { ButtonProps } from './type';

const buttonStyles = 'rounded-3xl flex flex-col items-center self-stretch px-6 py-3 text-sm font-semibold';

const ButtonInner = (
  { variant = 'filled', className, children, onClick, type = 'button' }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) => {
  const buttonVariant = Object.values(variants[variant]).join(' ');

  const classes = twMerge(classNames(buttonStyles, buttonVariant), className);

  return (
    <button className={classes} onClick={onClick} type={type} ref={ref}>
      {children}
    </button>
  );
};

const Button = forwardRef(ButtonInner);
export default Button;
