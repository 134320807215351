/**
 * @type {import('next-i18next').UserConfig}
 */
module.exports = {
  // https://www.i18next.com/overview/configuration-options#logging
  debug: process.env.NODE_ENV === 'development',
  i18n: {
    defaultLocale: 'de',
    locales: ['en', 'de'],
  },
  resources: {
    de: {
      common: require('./public/locales/de/common.json'),
    },
    en: {
      common: require('./public/locales/en/common.json'),
    },
  },
  returnEmptyString: true,
  reloadOnPrerender: process.env.NODE_ENV === 'development',

  /**
   * @link https://github.com/i18next/next-i18next#6-advanced-configuration
   */
  // saveMissing: false,
  // strictMode: true,
  // serializeConfig: false,
  // react: { useSuspense: false }
};
