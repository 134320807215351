import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React from 'react';

import Button from '@/components/bases/Button';
import Typography from '@/components/bases/Typography';
import { useThemeContext } from '@/contexts/ThemeContext';

import IconPhoneSVG from '../../../../public/static/btcimages/svgs/ic_phone.svg';
import IconXSVG from '../../../../public/static/btcimages/svgs/ic_x.svg';

const errorStatusMapping = (status?: number) => {
  switch (status) {
    case 400:
      return 'ERROR_400_description';
    case 401:
      return 'ERROR_401_description';
    case 403:
      return 'ERROR_403_description';
    case 404:
      return 'ERROR_404_description';
    case 500:
      return 'ERROR_500_description';
    default:
      return 'error_description';
  }
};

const ErrorContent = ({ status }: { status?: number }) => {
  const { t } = useTranslation();
  const { bSource } = useThemeContext();

  const errorDescription = t(errorStatusMapping(status));

  return (
    <div className='flex flex-col mobile:gap-6 desktop:gap-8'>
      <div className='m-auto max-w-[384px] text-center text-primary'>
        <div className='flex flex-col mobile:gap-2 desktop:gap-6'>
          <div className='flex items-center justify-center mobile:flex-col mobile:gap-1 desktop:flex-row desktop:gap-3 '>
            <div className='flex justify-center'>
              <span className='flex flex-col items-center justify-center rounded-full bg-inverse mobile:h-6 mobile:w-6 desktop:h-9 desktop:w-9'>
                <span className='relative mobile:h-4 mobile:w-4 desktop:h-[21px] desktop:w-[21px]'>
                  <IconXSVG width='100%' height='100%' color={bSource === 'hde' ? '#ffffff' : '#121212'} />
                </span>
              </span>
            </div>
            <Typography className='mobile:text-base desktop:text-xl' variant='h5'>
              {t('error_label')}
            </Typography>
          </div>
          <div className='flex flex-col gap-4 px-4'>
            <Typography className='font-normal mobile:text-xs desktop:text-sm' variant='paragraph'>
              {errorDescription}
            </Typography>
            <Typography className='font-semibold mobile:text-xs desktop:text-sm' variant='paragraph'>
              {t('error_advise')}
            </Typography>
          </div>
        </div>
      </div>
      <div className='m-auto flex w-full max-w-[384px] flex-col items-center gap-2 rounded-xl bg-white p-3'>
        <div className='text-center'>
          <Typography className='text-sm font-semibold' variant='paragraph' color='black'>
            {t('error_contact_title')}
          </Typography>
          <Typography className='text-xs font-normal leading-5' variant='paragraph' color='black'>
            {t('error_contact_subtitle')}
          </Typography>
        </div>
        <div className='flex flex-row items-center justify-center gap-2'>
          <div className='flex justify-center'>
            <span className='flex h-9 w-9 flex-col items-center justify-center rounded-full border border-solid border-black bg-white'>
              <span className='relative h-[21px] w-[21px]'>
                <IconPhoneSVG width='100%' height='100%' />
              </span>
            </span>
          </div>
          <div className='flex'>
            <Typography className='text-xl not-italic underline underline-offset-4' variant='h5'>
              +49 221 2077 600
            </Typography>
            <p className='pl-[2px] align-middle text-[10px] font-medium text-gray-500'>
              <sup className='top-0'>1,2</sup>
            </p>
          </div>
        </div>
        <div className='flex flex-col text-center'>
          <Typography className='text-[8px] font-normal leading-4 text-gray-500' variant='paragraph'>
            <sup className='top-[-0.7em] font-medium'>1</sup> {t('error_contact_helpline_sup1')}
          </Typography>
          <Typography className='text-[8px] font-normal leading-4 text-gray-500' variant='paragraph'>
            <sup className='top-[-0.7em] font-medium'>2</sup> {t('error_contact_helpline_sup1')}
          </Typography>
          <Typography className='text-[8px] font-normal leading-4 text-gray-500' variant='paragraph'>
            {t('error_contact_helpline_sup3')}
          </Typography>
        </div>
      </div>
      <div className='m-auto flex w-full max-w-[384px] justify-center'>
        <Link className='w-full' target='_blank' href='https://www.hrs.com/web3/selectHotline.do?activity=showHotlines'>
          <Button className='w-full' onClick={() => {}}>
            {t('error_contact_button')}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default ErrorContent;
