/* tslint:disable */
/* eslint-disable */
/**
 * BTC Checkin API
 * This api provides functionality to fetch booking/hotel information and submit data that user filled up in checkin app to HotelBird
 *
 * The version of the OpenAPI document: 0.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export * from "./api";
export * from "./configuration";

