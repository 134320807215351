import React from 'react';
import { twMerge } from 'tailwind-merge';

import { useThemeContext } from '@/contexts/ThemeContext';

import LogoHRSSVG from '../../../../public/static/btcimages/svgs/logo.svg';
import LogoHotelDeSVG from '../../../../public/static/btcimages/svgs/logo_hotelde.svg';

type LoadingProps = {
  isLoading: boolean;
};

const Loading = ({ isLoading }: LoadingProps) => {
  const { bSource } = useThemeContext();

  const classes = twMerge(
    'fixed h-full min-h-screen w-screen flex-col justify-center bg-primary left-0 top-0 z-50',
    isLoading ? 'flex' : 'hidden',
  );

  if (bSource === 'hde') {
    return (
      <div className={classes}>
        <div className='flex flex-col items-center justify-center'>
          <span className='animate-bounce'>
            <LogoHotelDeSVG width={300} height={55} />
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className={classes}>
      <div className='flex flex-col items-center justify-center'>
        <div className='-z-10 absolute inline-flex h-[560px] w-[560px] animate-[pulse_2s_ease-in-out_infinite] rounded-full bg-red-700 opacity-30'></div>
        <div className='-z-10 absolute inline-flex h-[325px] w-[325px] animate-[pulse_2s_ease-in-out_infinite] rounded-full bg-red-700 opacity-40'></div>
        <LogoHRSSVG width={130} height={130} />
      </div>
    </div>
  );
};

export default Loading;
