import { useCallback, useState } from 'react';

import { useWindowEvent } from './useWindowEvent';

type DefaultLocalStorageKey = 'btc_checkin_token' | 'btc_checkin_form_data';

const deserialize = <T>(value: string | null, fallbackValue: T) => {
  try {
    return JSON.parse(value ?? '') as T;
  } catch {
    // console.error('deserialize error on', { value });
    return fallbackValue;
  }
};

const serialize = <T>(value: T) => {
  try {
    return JSON.stringify(value);
  } catch (error) {
    // console.error('serialize error on', { value });
    return '';
  }
};

export function useLocalStorage<T>(key: DefaultLocalStorageKey, fallbackValue?: T) {
  const [value, setValue] = useState(() => {
    if (typeof window !== 'undefined') {
      return deserialize(localStorage.getItem(key), fallbackValue);
    }
    return fallbackValue;
  });

  useWindowEvent('storage', (event) => {
    if (event.storageArea === window.localStorage && event.key === key) {
      setValue(deserialize(event.newValue, fallbackValue));
    }
  });

  const setStorageValue = useCallback(
    (value: T) => {
      localStorage.setItem(key, serialize(value));
      setValue(value);
    },
    [key],
  );

  return [value, setStorageValue] as const;
}
