import { useTranslation } from 'next-i18next';
import React from 'react';

import PoliciesAndPrivacy from '@/components/interfaces/CheckinForm/PoliciesAndPrivacy';
import { useAppContext } from '@/contexts/AppContext';
import { useFooterContext } from '@/contexts/FooterContext';

import Button from '../Button';

const Footer = () => {
  const { t } = useTranslation();
  const { leftButtonConfig, rightButtonConfig, openTnC, openPP, openIP } = useFooterContext();
  const { checkinInfo } = useAppContext();

  const signatureConfig = checkinInfo?.checkinConfig?.checkinFields?.signature;

  return (
    <footer className='desktop:shadow-footer desktop:fixed desktop:bottom-0 desktop:h-[78px] mobile:h-[54px] desktop:pb-0 mobile:pb-3 w-full z-10 items-center justify-center bg-white flex'>
      <div className='flex w-full max-w-[1216px] gap-8 mobile:justify-center desktop:justify-between'>
        {leftButtonConfig ? (
          <Button
            className='w-44 mobile:hidden desktop:block'
            variant='default'
            type={leftButtonConfig.type}
            onClick={leftButtonConfig.onClick}
          >
            {leftButtonConfig.label}
          </Button>
        ) : (
          signatureConfig !== 'hidden' && <div className='h-1 w-44 mobile:hidden desktop:block' />
        )}
        <ul className='flex items-center justify-center gap-8 text-[10px] font-semibold text-gray-300'>
          <li className='hover:underline cursor-pointer' onClick={openTnC}>
            {t('footer_terms')}
          </li>
          <li className='hover:underline cursor-pointer' onClick={openPP}>
            {t('footer_privacy')}
          </li>
          <li className='hover:underline cursor-pointer' onClick={openIP}>
            {t('footer_imprint')}
          </li>
        </ul>
        {signatureConfig === 'hidden' && (
          <div className='desktop:flex items-center mobile:hidden'>
            <PoliciesAndPrivacy />
          </div>
        )}
        {rightButtonConfig ? (
          <Button
            type={rightButtonConfig.type}
            className='w-44 mobile:hidden desktop:block'
            onClick={rightButtonConfig.onClick}
          >
            {rightButtonConfig.label}
          </Button>
        ) : (
          <div className='h-1 w-44 mobile:hidden desktop:block' />
        )}
      </div>
    </footer>
  );
};

export default Footer;
