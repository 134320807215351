/* tslint:disable */
/* eslint-disable */
/**
 * BTC Checkin API
 * This api provides functionality to fetch booking/hotel information and submit data that user filled up in checkin app to HotelBird
 *
 * The version of the OpenAPI document: 0.0.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * Additional guest information
 * @export
 * @interface AdditionalGuest
 */
export interface AdditionalGuest {
    /**
     * First name of guest
     * @type {string}
     * @memberof AdditionalGuest
     */
    'firstName': string;
    /**
     * Last name of guest
     * @type {string}
     * @memberof AdditionalGuest
     */
    'lastName': string;
    /**
     * Nationality from the guest (ISO 639-1: 2 Letters)
     * @type {string}
     * @memberof AdditionalGuest
     */
    'nationalityCode': string;
    /**
     * Unique document number. It is validated like the travel document of the main guest with the checkin field \'travelDocument\' configuration hidden, required, optional, foreigners
     * @type {string}
     * @memberof AdditionalGuest
     */
    'docNumber'?: string;
}
/**
 * Address of an object (eg. Guest, Company)
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * Company name. Only required for billingAddress
     * @type {string}
     * @memberof Address
     */
    'companyName': string;
    /**
     * Street of the address
     * @type {string}
     * @memberof Address
     */
    'street': string;
    /**
     * Complementary info to the address
     * @type {string}
     * @memberof Address
     */
    'additionalLine'?: string;
    /**
     * Zip code of the address
     * @type {string}
     * @memberof Address
     */
    'zipCode': string;
    /**
     * City of the address
     * @type {string}
     * @memberof Address
     */
    'city': string;
    /**
     * Country code of the hotel (ISO 639-1: 2 Letters)
     * @type {string}
     * @memberof Address
     */
    'countryCode': string;
}
/**
 * 
 * @export
 * @interface AllSupportedHotelsResponse
 */
export interface AllSupportedHotelsResponse {
    /**
     * 
     * @type {Array<number>}
     * @memberof AllSupportedHotelsResponse
     */
    'result'?: Array<number>;
}
/**
 * This is the billing address that the customer entered on the booking funnel form via hrs.com. It could be personal or company address
 * @export
 * @interface BillingAddress
 */
export interface BillingAddress {
    /**
     * Street of the address including house number
     * @type {string}
     * @memberof BillingAddress
     */
    'street': string;
    /**
     * Zip code of the address
     * @type {string}
     * @memberof BillingAddress
     */
    'zipCode': string;
    /**
     * City name
     * @type {string}
     * @memberof BillingAddress
     */
    'city': string;
    /**
     * Country code of the hotel (ISO 639-1: 2 Letters)
     * @type {string}
     * @memberof BillingAddress
     */
    'countryCode': string;
}
/**
 * Information about the booking fetched from Partner (HotelBird)
 * @export
 * @interface Booking
 */
export interface Booking {
    /**
     * HRS booking id
     * @type {string}
     * @memberof Booking
     */
    'hrsBookingId': string;
    /**
     * Partner booking id
     * @type {string}
     * @memberof Booking
     */
    'partnerBookingId': string;
    /**
     * calculated number of rooms
     * @type {number}
     * @memberof Booking
     */
    'numberOfRooms': number;
    /**
     * calculated duration of stay in nights
     * @type {number}
     * @memberof Booking
     */
    'numberOfNights': number;
    /**
     * calculated number of guests
     * @type {number}
     * @memberof Booking
     */
    'numberOfGuests': number;
    /**
     * fromDate
     * @type {string}
     * @memberof Booking
     */
    'checkinDate': string;
    /**
     * toDate
     * @type {string}
     * @memberof Booking
     */
    'checkoutDate': string;
    /**
     * Booking ordered email
     * @type {string}
     * @memberof Booking
     */
    'customerEmail': string;
    /**
     * Describes the current status of a booking at can be:<br/>* inactive: for inactive booking\'s<br/>* cancelled: for cancelled booking\'s<br/>* waitlist: for currently not yet confirmed bookings<br/>* reserved: for bookings that are reserved, and not yet (pre) checked in, checked out or expired<br/>* pre_checked_in: bookings pre checked-in, but not yet checked-in in pms<br/>* missing_dcc: booking is pre checked-in, there is no or an invalid dcc validation result and it is not yet checked-in in pms. after the arrival day if the booking is not no-show the guest checked in at the reception and the dcc is not required digitally anymore, the checkout can be allowed.<br/>* preauth_prepayment_fail: if a hotel requires a preauth or prepayment before actual check-in and this failed, the booking will have this status until user explicitly provides a correct payment method<br/>* preauth_prepayment_retry: if a hotel requires a preauth or prepayment before actual check-in, preauth or prepayment failed and user provided another payment method, this status will be set until the preauth / prepayment is tried again<br/>* checked_in: bookings checked in in pms and not yet checked out<br/>* checked_out: bookings checked out in pms<br/>* no_show: bookings that are set on no show in pms or are actually expired and not checked-in or checked-out
     * @type {string}
     * @memberof Booking
     */
    'status': BookingStatusEnum;
    /**
     * 
     * @type {CheckinPass}
     * @memberof Booking
     */
    'checkinPass'?: CheckinPass;
    /**
     * 
     * @type {BillingAddress}
     * @memberof Booking
     */
    'billingAddress'?: BillingAddress;
    /**
     * Firstname of the person 
     * @type {string}
     * @memberof Booking
     */
    'firstName'?: string;
    /**
     * Lastname of the person 
     * @type {string}
     * @memberof Booking
     */
    'lastName'?: string;
    /**
     * Guest gender:<br/>- Male<br/>- Female<br/>- Other
     * @type {string}
     * @memberof Booking
     */
    'gender'?: BookingGenderEnum;
    /**
     * Phone of the guest
     * @type {string}
     * @memberof Booking
     */
    'phone'?: string;
    /**
     * aka Business Segment on HRS: <br/>- Leisure<br/>- Business<br/>- Other
     * @type {string}
     * @memberof Booking
     */
    'purposeOfStay'?: BookingPurposeOfStayEnum;
}

export const BookingStatusEnum = {
    Inactive: 'inactive',
    Cancelled: 'cancelled',
    Waitlist: 'waitlist',
    Reserved: 'reserved',
    PreCheckedIn: 'pre_checked_in',
    MissingDcc: 'missing_dcc',
    PreauthPrepaymentFail: 'preauth_prepayment_fail',
    PreauthPrepaymentRetry: 'preauth_prepayment_retry',
    CheckedIn: 'checked_in',
    CheckedOut: 'checked_out',
    NoShow: 'no_show'
} as const;

export type BookingStatusEnum = typeof BookingStatusEnum[keyof typeof BookingStatusEnum];
export const BookingGenderEnum = {
    Male: 'Male',
    Female: 'Female',
    Other: 'Other'
} as const;

export type BookingGenderEnum = typeof BookingGenderEnum[keyof typeof BookingGenderEnum];
export const BookingPurposeOfStayEnum = {
    Leisure: 'Leisure',
    Business: 'Business',
    Other: 'Other'
} as const;

export type BookingPurposeOfStayEnum = typeof BookingPurposeOfStayEnum[keyof typeof BookingPurposeOfStayEnum];

/**
 * 
 * @export
 * @interface Charset
 */
export interface Charset {
    /**
     * 
     * @type {string}
     * @memberof Charset
     */
    'name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Charset
     */
    'aliases'?: Array<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof Charset
     */
    'aliasSet'?: Set<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Charset
     */
    'registered'?: boolean;
}
/**
 * Info for checkin, provided by external partner
 * @export
 * @interface CheckinConfig
 */
export interface CheckinConfig {
    /**
     * 
     * @type {CheckinFields}
     * @memberof CheckinConfig
     */
    'checkinFields': CheckinFields;
    /**
     * 
     * @type {TextComponents}
     * @memberof CheckinConfig
     */
    'textComponents': TextComponents;
}
/**
 * Set of fields for the checkin which are required, optional, hidden; does not allow custom fields
 * @export
 * @interface CheckinFields
 */
export interface CheckinFields {
    /**
     * Setting for the purposeOfStay field how it should be handled during the checkin process
     * @type {string}
     * @memberof CheckinFields
     */
    'purposeOfStay': CheckinFieldsPurposeOfStayEnum;
    /**
     * Setting for the businessInvoice field how it should be handled during the checkin process
     * @type {string}
     * @memberof CheckinFields
     */
    'businessInvoice': CheckinFieldsBusinessInvoiceEnum;
    /**
     * Setting for the gender field how it should be handled during the checkin process
     * @type {string}
     * @memberof CheckinFields
     */
    'gender': CheckinFieldsGenderEnum;
    /**
     * Setting for the dateOfBirth field how it should be handled during the checkin process
     * @type {string}
     * @memberof CheckinFields
     */
    'dateOfBirth': CheckinFieldsDateOfBirthEnum;
    /**
     * Setting for the nationality field how it should be handled during the checkin process
     * @type {string}
     * @memberof CheckinFields
     */
    'nationality': CheckinFieldsNationalityEnum;
    /**
     * Setting for the phone field how it should be handled during the checkin process
     * @type {string}
     * @memberof CheckinFields
     */
    'phone': CheckinFieldsPhoneEnum;
    /**
     * Setting for the phone field how it should be handled during the checkin process
     * @type {string}
     * @memberof CheckinFields
     */
    'address': CheckinFieldsAddressEnum;
    /**
     * Setting for the travelDocument field how it should be handled during the checkin process
     * @type {string}
     * @memberof CheckinFields
     */
    'travelDocument': CheckinFieldsTravelDocumentEnum;
    /**
     * Setting for the additionalGuests field how it should be handled during the checkin process
     * @type {string}
     * @memberof CheckinFields
     */
    'additionalGuests': CheckinFieldsAdditionalGuestsEnum;
    /**
     * Setting for the nextDestination field how it should be handled during the checkin process
     * @type {string}
     * @memberof CheckinFields
     */
    'nextDestination': CheckinFieldsNextDestinationEnum;
    /**
     * Setting for the dcc validation how it should be handled during the checkin process
     * @type {string}
     * @memberof CheckinFields
     */
    'dcc': CheckinFieldsDccEnum;
    /**
     * Setting for the signature field how it should be handled during the checkin process
     * @type {string}
     * @memberof CheckinFields
     */
    'signature': CheckinFieldsSignatureEnum;
}

export const CheckinFieldsPurposeOfStayEnum = {
    Required: 'required',
    Optional: 'optional',
    Hidden: 'hidden'
} as const;

export type CheckinFieldsPurposeOfStayEnum = typeof CheckinFieldsPurposeOfStayEnum[keyof typeof CheckinFieldsPurposeOfStayEnum];
export const CheckinFieldsBusinessInvoiceEnum = {
    Required: 'required',
    Optional: 'optional',
    Hidden: 'hidden'
} as const;

export type CheckinFieldsBusinessInvoiceEnum = typeof CheckinFieldsBusinessInvoiceEnum[keyof typeof CheckinFieldsBusinessInvoiceEnum];
export const CheckinFieldsGenderEnum = {
    Required: 'required',
    Optional: 'optional',
    Hidden: 'hidden'
} as const;

export type CheckinFieldsGenderEnum = typeof CheckinFieldsGenderEnum[keyof typeof CheckinFieldsGenderEnum];
export const CheckinFieldsDateOfBirthEnum = {
    Required: 'required',
    Optional: 'optional',
    Hidden: 'hidden'
} as const;

export type CheckinFieldsDateOfBirthEnum = typeof CheckinFieldsDateOfBirthEnum[keyof typeof CheckinFieldsDateOfBirthEnum];
export const CheckinFieldsNationalityEnum = {
    Required: 'required',
    Optional: 'optional',
    Hidden: 'hidden'
} as const;

export type CheckinFieldsNationalityEnum = typeof CheckinFieldsNationalityEnum[keyof typeof CheckinFieldsNationalityEnum];
export const CheckinFieldsPhoneEnum = {
    Required: 'required',
    Optional: 'optional',
    Hidden: 'hidden'
} as const;

export type CheckinFieldsPhoneEnum = typeof CheckinFieldsPhoneEnum[keyof typeof CheckinFieldsPhoneEnum];
export const CheckinFieldsAddressEnum = {
    Required: 'required',
    Optional: 'optional',
    Hidden: 'hidden'
} as const;

export type CheckinFieldsAddressEnum = typeof CheckinFieldsAddressEnum[keyof typeof CheckinFieldsAddressEnum];
export const CheckinFieldsTravelDocumentEnum = {
    Hidden: 'hidden',
    Required: 'required',
    Foreigners: 'foreigners'
} as const;

export type CheckinFieldsTravelDocumentEnum = typeof CheckinFieldsTravelDocumentEnum[keyof typeof CheckinFieldsTravelDocumentEnum];
export const CheckinFieldsAdditionalGuestsEnum = {
    Required: 'required',
    Optional: 'optional',
    Hidden: 'hidden'
} as const;

export type CheckinFieldsAdditionalGuestsEnum = typeof CheckinFieldsAdditionalGuestsEnum[keyof typeof CheckinFieldsAdditionalGuestsEnum];
export const CheckinFieldsNextDestinationEnum = {
    Required: 'required',
    Optional: 'optional',
    Hidden: 'hidden'
} as const;

export type CheckinFieldsNextDestinationEnum = typeof CheckinFieldsNextDestinationEnum[keyof typeof CheckinFieldsNextDestinationEnum];
export const CheckinFieldsDccEnum = {
    _3gPlus: '3g_plus',
    _3g: '3g',
    _2g: '2g',
    _2gPlus: '2g_plus',
    Hidden: 'hidden'
} as const;

export type CheckinFieldsDccEnum = typeof CheckinFieldsDccEnum[keyof typeof CheckinFieldsDccEnum];
export const CheckinFieldsSignatureEnum = {
    Required: 'required',
    Optional: 'optional',
    Hidden: 'hidden'
} as const;

export type CheckinFieldsSignatureEnum = typeof CheckinFieldsSignatureEnum[keyof typeof CheckinFieldsSignatureEnum];

/**
 * Generic subset of what hotelbird provides if user has already checked in
 * @export
 * @interface CheckinPass
 */
export interface CheckinPass {
    /**
     * Base 64 encoded string with PNG of the QR Code for the guest to use at the terminal
     * @type {string}
     * @memberof CheckinPass
     */
    'qrCode': string;
    /**
     * Language depended text checkin pass
     * @type {string}
     * @memberof CheckinPass
     */
    'title': string;
    /**
     * Language depended text checkin pass
     * @type {string}
     * @memberof CheckinPass
     */
    'info': string;
    /**
     * Language depended text after successful checkin
     * @type {string}
     * @memberof CheckinPass
     */
    'checkinSuccessInfo': string;
}
/**
 * Status of checkin
 * @export
 * @interface CheckinStatus
 */
export interface CheckinStatus {
    /**
     * Result of POST /checking operation. OK or FAILED
     * @type {boolean}
     * @memberof CheckinStatus
     */
    'result'?: boolean;
    /**
     * Describes the current status of a booking at can be:<br/>* inactive: for inactive booking\'s<br/>* cancelled: for cancelled booking\'s<br/>* waitlist: for currently not yet confirmed bookings<br/>* reserved: for bookings that are reserved, and not yet (pre) checked in, checked out or expired<br/>* pre_checked_in: bookings pre checked-in, but not yet checked-in in pms<br/>* missing_dcc: booking is pre checked-in, there is no or an invalid dcc validation result and it is not yet checked-in in pms. after the arrival day if the booking is not no-show the guest checked in at the reception and the dcc is not required digitally anymore, the checkout can be allowed.<br/>* preauth_prepayment_fail: if a hotel requires a preauth or prepayment before actual check-in and this failed, the booking will have this status until user explicitly provides a correct payment method<br/>* preauth_prepayment_retry: if a hotel requires a preauth or prepayment before actual check-in, preauth or prepayment failed and user provided another payment method, this status will be set until the preauth / prepayment is tried again<br/>* checked_in: bookings checked in in pms and not yet checked out<br/>* checked_out: bookings checked out in pms<br/>* no_show: bookings that are set on no show in pms or are actually expired and not checked-in or checked-out
     * @type {string}
     * @memberof CheckinStatus
     */
    'status'?: CheckinStatusStatusEnum;
    /**
     * Room code for booking -- ??Q: is this the room number or the code to unlock??
     * @type {string}
     * @memberof CheckinStatus
     */
    'roomCode'?: string;
    /**
     * 
     * @type {CheckinPass}
     * @memberof CheckinStatus
     */
    'checkinPass'?: CheckinPass;
}

export const CheckinStatusStatusEnum = {
    Inactive: 'inactive',
    Cancelled: 'cancelled',
    Waitlist: 'waitlist',
    Reserved: 'reserved',
    PreCheckedIn: 'pre_checked_in',
    MissingDcc: 'missing_dcc',
    PreauthPrepaymentFail: 'preauth_prepayment_fail',
    PreauthPrepaymentRetry: 'preauth_prepayment_retry',
    CheckedIn: 'checked_in',
    CheckedOut: 'checked_out',
    NoShow: 'no_show'
} as const;

export type CheckinStatusStatusEnum = typeof CheckinStatusStatusEnum[keyof typeof CheckinStatusStatusEnum];

/**
 * 
 * @export
 * @interface Expression
 */
export interface Expression {
    /**
     * 
     * @type {string}
     * @memberof Expression
     */
    'namespace'?: string;
    /**
     * 
     * @type {Array<Part>}
     * @memberof Expression
     */
    'parts'?: Array<Part>;
    /**
     * 
     * @type {boolean}
     * @memberof Expression
     */
    'literal'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof Expression
     */
    'literalValue'?: object;
    /**
     * 
     * @type {Origin}
     * @memberof Expression
     */
    'origin'?: Origin;
    /**
     * 
     * @type {number}
     * @memberof Expression
     */
    'generatedId'?: number;
}
/**
 * response object of complete checkin
 * @export
 * @interface GetCheckinResponse
 */
export interface GetCheckinResponse {
    /**
     * 
     * @type {Hotel}
     * @memberof GetCheckinResponse
     */
    'hotel': Hotel;
    /**
     * 
     * @type {Booking}
     * @memberof GetCheckinResponse
     */
    'booking': Booking;
    /**
     * 
     * @type {CheckinConfig}
     * @memberof GetCheckinResponse
     */
    'checkinConfig': CheckinConfig;
}
/**
 * Information about the orderer of the booking
 * @export
 * @interface Guest
 */
export interface Guest {
    /**
     * Firstname of the person 
     * @type {string}
     * @memberof Guest
     */
    'firstName': string;
    /**
     * Lastname of the person 
     * @type {string}
     * @memberof Guest
     */
    'lastName': string;
    /**
     * POST ONLY: Guests gender:<br/>- Male<br/>- Female<br/>- Other
     * @type {string}
     * @memberof Guest
     */
    'gender'?: GuestGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof Guest
     */
    'dateOfBirth'?: string;
    /**
     * Phone of the guest
     * @type {string}
     * @memberof Guest
     */
    'phone'?: string;
    /**
     * 
     * @type {Address}
     * @memberof Guest
     */
    'personalAddress'?: Address;
    /**
     * 
     * @type {Address}
     * @memberof Guest
     */
    'billingAddress'?: Address;
    /**
     * 
     * @type {TravelDocument}
     * @memberof Guest
     */
    'travelDocument'?: TravelDocument;
    /**
     * POST ONLY: Nationality from the guest (ISO 639-1: 2 Letters)
     * @type {string}
     * @memberof Guest
     */
    'nationality'?: string;
    /**
     * Guest requests an invoice splitting
     * @type {boolean}
     * @memberof Guest
     */
    'businessInvoice'?: boolean;
    /**
     * Purpose of stay will be stored on the registration form: <br/>- Leisure<br/>- Business<br/>- Other
     * @type {string}
     * @memberof Guest
     */
    'purposeOfStay'?: GuestPurposeOfStayEnum;
    /**
     * The next destination as string
     * @type {string}
     * @memberof Guest
     */
    'nextDestination'?: string;
}

export const GuestGenderEnum = {
    Male: 'Male',
    Female: 'Female',
    Other: 'Other'
} as const;

export type GuestGenderEnum = typeof GuestGenderEnum[keyof typeof GuestGenderEnum];
export const GuestPurposeOfStayEnum = {
    Leisure: 'Leisure',
    Business: 'Business',
    Other: 'Other'
} as const;

export type GuestPurposeOfStayEnum = typeof GuestPurposeOfStayEnum[keyof typeof GuestPurposeOfStayEnum];

/**
 * Hotel meta data fetched from HRS
 * @export
 * @interface Hotel
 */
export interface Hotel {
    /**
     * HRS Hotel Id
     * @type {string}
     * @memberof Hotel
     */
    'hrsHotelId': string;
    /**
     * Partner Hotel Id
     * @type {string}
     * @memberof Hotel
     */
    'partnerHotelId': string;
    /**
     * HRS Hotel Name
     * @type {string}
     * @memberof Hotel
     */
    'hotelName': string;
    /**
     * HRS Stars
     * @type {number}
     * @memberof Hotel
     */
    'numberOfStars': number;
    /**
     * Needed to compare to guest nationality: travel document is conditionally required
     * @type {string}
     * @memberof Hotel
     */
    'countryCode': string;
    /**
     * HRS Hotel Image
     * @type {string}
     * @memberof Hotel
     */
    'hotelImageSrc': string;
    /**
     * 
     * @type {Address}
     * @memberof Hotel
     */
    'hotelAddress': Address;
}
/**
 * 
 * @export
 * @interface Locale
 */
export interface Locale {
    /**
     * 
     * @type {string}
     * @memberof Locale
     */
    'language'?: string;
    /**
     * 
     * @type {string}
     * @memberof Locale
     */
    'script'?: string;
    /**
     * 
     * @type {string}
     * @memberof Locale
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof Locale
     */
    'variant'?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof Locale
     */
    'extensionKeys'?: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof Locale
     */
    'unicodeLocaleAttributes'?: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof Locale
     */
    'unicodeLocaleKeys'?: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof Locale
     */
    'iSO3Language'?: string;
    /**
     * 
     * @type {string}
     * @memberof Locale
     */
    'iSO3Country'?: string;
    /**
     * 
     * @type {string}
     * @memberof Locale
     */
    'displayLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof Locale
     */
    'displayScript'?: string;
    /**
     * 
     * @type {string}
     * @memberof Locale
     */
    'displayCountry'?: string;
    /**
     * 
     * @type {string}
     * @memberof Locale
     */
    'displayVariant'?: string;
    /**
     * 
     * @type {string}
     * @memberof Locale
     */
    'displayName'?: string;
}
/**
 * 
 * @export
 * @interface Origin
 */
export interface Origin {
    /**
     * 
     * @type {number}
     * @memberof Origin
     */
    'line'?: number;
    /**
     * 
     * @type {number}
     * @memberof Origin
     */
    'lineCharacterStart'?: number;
    /**
     * 
     * @type {number}
     * @memberof Origin
     */
    'lineCharacterEnd'?: number;
    /**
     * 
     * @type {string}
     * @memberof Origin
     */
    'templateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Origin
     */
    'templateGeneratedId'?: string;
    /**
     * 
     * @type {OriginVariant}
     * @memberof Origin
     */
    'variant'?: OriginVariant;
}
/**
 * 
 * @export
 * @interface OriginVariant
 */
export interface OriginVariant {
    /**
     * 
     * @type {Locale}
     * @memberof OriginVariant
     */
    'locale'?: Locale;
    /**
     * 
     * @type {string}
     * @memberof OriginVariant
     */
    'contentType'?: string;
    /**
     * 
     * @type {Charset}
     * @memberof OriginVariant
     */
    'encoding'?: Charset;
    /**
     * 
     * @type {number}
     * @memberof OriginVariant
     */
    'hashCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof OriginVariant
     */
    'mediaType'?: string;
    /**
     * 
     * @type {Charset}
     * @memberof OriginVariant
     */
    'charset'?: Charset;
}
/**
 * 
 * @export
 * @interface ParameterDeclaration
 */
export interface ParameterDeclaration {
    /**
     * 
     * @type {string}
     * @memberof ParameterDeclaration
     */
    'typeInfo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParameterDeclaration
     */
    'key'?: string;
    /**
     * 
     * @type {Expression}
     * @memberof ParameterDeclaration
     */
    'defaultValue'?: Expression;
    /**
     * 
     * @type {Origin}
     * @memberof ParameterDeclaration
     */
    'origin'?: Origin;
}
/**
 * 
 * @export
 * @interface Part
 */
export interface Part {
    /**
     * 
     * @type {string}
     * @memberof Part
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Part
     */
    'typeInfo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Part
     */
    'virtualMethod'?: boolean;
}
/**
 * Body for pre checkin
 * @export
 * @interface PostCheckinBody
 */
export interface PostCheckinBody {
    /**
     * Secure token: contains hrsBookingId, partnerBookingId, hrsHotelId, partnerHotelId and customerEmail
     * @type {string}
     * @memberof PostCheckinBody
     */
    'secureToken': string;
    /**
     * Needed to define the language of the confirmation email. ISO 639-3 language string
     * @type {string}
     * @memberof PostCheckinBody
     */
    'language': string;
    /**
     * Needed to set icon & css styles on the confirmation email. If \'hde\' hotel.de style will apply. Any other value, hrs.com styles
     * @type {string}
     * @memberof PostCheckinBody
     */
    'bSource': string;
    /**
     * 
     * @type {Guest}
     * @memberof PostCheckinBody
     */
    'guest': Guest;
    /**
     * 
     * @type {Hotel}
     * @memberof PostCheckinBody
     */
    'hotel': Hotel;
    /**
     * 
     * @type {Signature}
     * @memberof PostCheckinBody
     */
    'signature'?: Signature;
    /**
     * Container for additional guests
     * @type {Set<AdditionalGuest>}
     * @memberof PostCheckinBody
     */
    'additionalGuests'?: Set<AdditionalGuest>;
}
/**
 * Checkin status returned by partner
 * @export
 * @interface PostCheckinResponse
 */
export interface PostCheckinResponse {
    /**
     * 
     * @type {CheckinStatus}
     * @memberof PostCheckinResponse
     */
    'checkinStatus': CheckinStatus;
}
/**
 * Signature container for submitting data
 * @export
 * @interface Signature
 */
export interface Signature {
    /**
     * UTC time of creation (ISO 8601)
     * @type {string}
     * @memberof Signature
     */
    'timestamp': string;
    /**
     * Base 64 encoded string with PNG containing signature of guest
     * @type {string}
     * @memberof Signature
     */
    'signatureImg': string;
}
/**
 * Simple json containing result: true | false
 * @export
 * @interface SupportedHotelResult
 */
export interface SupportedHotelResult {
    /**
     * if hotelId is found, true
     * @type {boolean}
     * @memberof SupportedHotelResult
     */
    'result': boolean;
}
/**
 * 
 * @export
 * @interface Template
 */
export interface Template {
    /**
     * 
     * @type {Array<Expression>}
     * @memberof Template
     */
    'expressions'?: Array<Expression>;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    'generatedId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Template
     */
    'id'?: string;
    /**
     * 
     * @type {OriginVariant}
     * @memberof Template
     */
    'variant'?: OriginVariant;
    /**
     * 
     * @type {Array<ParameterDeclaration>}
     * @memberof Template
     */
    'parameterDeclarations'?: Array<ParameterDeclaration>;
}
/**
 * 
 * @export
 * @interface TemplateInstance
 */
export interface TemplateInstance {
    /**
     * 
     * @type {number}
     * @memberof TemplateInstance
     */
    'timeout'?: number;
    /**
     * 
     * @type {Template}
     * @memberof TemplateInstance
     */
    'template'?: Template;
}
/**
 * Text components container for hotel object
 * @export
 * @interface TextComponents
 */
export interface TextComponents {
    /**
     * Language depended description of hotel
     * @type {string}
     * @memberof TextComponents
     */
    'description': string;
    /**
     * Language depended text of terms to accept for using the online checkin
     * @type {string}
     * @memberof TextComponents
     */
    'checkinLegalNotice': string;
    /**
     * Language depended text after successful checkin
     * @type {string}
     * @memberof TextComponents
     */
    'checkinSuccessInfo': string;
    /**
     * Language depended text checkin pass
     * @type {string}
     * @memberof TextComponents
     */
    'checkinPassTitle': string;
    /**
     * Language depended text checkin pass
     * @type {string}
     * @memberof TextComponents
     */
    'checkinPassInfo': string;
}
/**
 * TravelDocument of a guest
 * @export
 * @interface TravelDocument
 */
export interface TravelDocument {
    /**
     * The following types are possible:<br/>- Passport<br/>- IdentityCard
     * @type {string}
     * @memberof TravelDocument
     */
    'docType': TravelDocumentDocTypeEnum;
    /**
     * Unique Document number
     * @type {string}
     * @memberof TravelDocument
     */
    'docNumber': string;
    /**
     * Date of expiry (yyyy-mm-dd)
     * @type {string}
     * @memberof TravelDocument
     */
    'expiryDate': string;
    /**
     * Issuing Authority
     * @type {string}
     * @memberof TravelDocument
     */
    'issuingAuthority': string;
}

export const TravelDocumentDocTypeEnum = {
    Passport: 'Passport',
    IdentityCard: 'IdentityCard'
} as const;

export type TravelDocumentDocTypeEnum = typeof TravelDocumentDocTypeEnum[keyof typeof TravelDocumentDocTypeEnum];

/**
 * 
 * @export
 * @interface Variant
 */
export interface Variant {
    /**
     * 
     * @type {Locale}
     * @memberof Variant
     */
    'locale'?: Locale;
    /**
     * 
     * @type {string}
     * @memberof Variant
     */
    'contentType'?: string;
    /**
     * 
     * @type {Charset}
     * @memberof Variant
     */
    'encoding'?: Charset;
    /**
     * 
     * @type {number}
     * @memberof Variant
     */
    'hashCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof Variant
     */
    'mediaType'?: string;
    /**
     * 
     * @type {Charset}
     * @memberof Variant
     */
    'charset'?: Charset;
}

/**
 * CheckinApi - axios parameter creator
 * @export
 */
export const CheckinApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Requests Info related to Hotel (object of stay), Booking (details of the stay), Guest (not in v1) & Checkin\'s config (setup made by the hotel through external partner)
         * @summary Request all the info related to the checkin
         * @param {string} secureToken Secure token: contains hrsBookingId, partnerBookingId, hrsHotelId, partnerHotelId and customerEmail
         * @param {string} language ISO 639-3 language string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCheckin: async (secureToken: string, language: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'secureToken' is not null or undefined
            assertParamExists('getCheckin', 'secureToken', secureToken)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('getCheckin', 'language', language)
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (secureToken !== undefined) {
                localVarQueryParameter['secureToken'] = secureToken;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get api specs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/openapi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers
         * @summary OPTIONS method used by browsers when issuing preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionsCheckin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send the data filled in by the user back to the server in order to perform the contacless checkin.
         * @summary Send all the info related to the checkin
         * @param {PostCheckinBody} postCheckinBody Body to create a checkin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCheckin: async (postCheckinBody: PostCheckinBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postCheckinBody' is not null or undefined
            assertParamExists('postCheckin', 'postCheckinBody', postCheckinBody)
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCheckinBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary View api specs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewSpecs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/swagger-ui`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CheckinApi - functional programming interface
 * @export
 */
export const CheckinApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CheckinApiAxiosParamCreator(configuration)
    return {
        /**
         * Requests Info related to Hotel (object of stay), Booking (details of the stay), Guest (not in v1) & Checkin\'s config (setup made by the hotel through external partner)
         * @summary Request all the info related to the checkin
         * @param {string} secureToken Secure token: contains hrsBookingId, partnerBookingId, hrsHotelId, partnerHotelId and customerEmail
         * @param {string} language ISO 639-3 language string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCheckin(secureToken: string, language: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCheckinResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCheckin(secureToken, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get api specs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSpecs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSpecs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers
         * @summary OPTIONS method used by browsers when issuing preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionsCheckin(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionsCheckin(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Send the data filled in by the user back to the server in order to perform the contacless checkin.
         * @summary Send all the info related to the checkin
         * @param {PostCheckinBody} postCheckinBody Body to create a checkin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCheckin(postCheckinBody: PostCheckinBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostCheckinResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCheckin(postCheckinBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary View api specs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async viewSpecs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateInstance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.viewSpecs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CheckinApi - factory interface
 * @export
 */
export const CheckinApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CheckinApiFp(configuration)
    return {
        /**
         * Requests Info related to Hotel (object of stay), Booking (details of the stay), Guest (not in v1) & Checkin\'s config (setup made by the hotel through external partner)
         * @summary Request all the info related to the checkin
         * @param {string} secureToken Secure token: contains hrsBookingId, partnerBookingId, hrsHotelId, partnerHotelId and customerEmail
         * @param {string} language ISO 639-3 language string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCheckin(secureToken: string, language: string, options?: any): AxiosPromise<GetCheckinResponse> {
            return localVarFp.getCheckin(secureToken, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get api specs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecs(options?: any): AxiosPromise<void> {
            return localVarFp.getSpecs(options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers
         * @summary OPTIONS method used by browsers when issuing preflight requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionsCheckin(options?: any): AxiosPromise<void> {
            return localVarFp.optionsCheckin(options).then((request) => request(axios, basePath));
        },
        /**
         * Send the data filled in by the user back to the server in order to perform the contacless checkin.
         * @summary Send all the info related to the checkin
         * @param {PostCheckinBody} postCheckinBody Body to create a checkin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCheckin(postCheckinBody: PostCheckinBody, options?: any): AxiosPromise<PostCheckinResponse> {
            return localVarFp.postCheckin(postCheckinBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary View api specs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewSpecs(options?: any): AxiosPromise<TemplateInstance> {
            return localVarFp.viewSpecs(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CheckinApi - object-oriented interface
 * @export
 * @class CheckinApi
 * @extends {BaseAPI}
 */
export class CheckinApi extends BaseAPI {
    /**
     * Requests Info related to Hotel (object of stay), Booking (details of the stay), Guest (not in v1) & Checkin\'s config (setup made by the hotel through external partner)
     * @summary Request all the info related to the checkin
     * @param {string} secureToken Secure token: contains hrsBookingId, partnerBookingId, hrsHotelId, partnerHotelId and customerEmail
     * @param {string} language ISO 639-3 language string
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckinApi
     */
    public getCheckin(secureToken: string, language: string, options?: AxiosRequestConfig) {
        return CheckinApiFp(this.configuration).getCheckin(secureToken, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get api specs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckinApi
     */
    public getSpecs(options?: AxiosRequestConfig) {
        return CheckinApiFp(this.configuration).getSpecs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers
     * @summary OPTIONS method used by browsers when issuing preflight requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckinApi
     */
    public optionsCheckin(options?: AxiosRequestConfig) {
        return CheckinApiFp(this.configuration).optionsCheckin(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send the data filled in by the user back to the server in order to perform the contacless checkin.
     * @summary Send all the info related to the checkin
     * @param {PostCheckinBody} postCheckinBody Body to create a checkin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckinApi
     */
    public postCheckin(postCheckinBody: PostCheckinBody, options?: AxiosRequestConfig) {
        return CheckinApiFp(this.configuration).postCheckin(postCheckinBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary View api specs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckinApi
     */
    public viewSpecs(options?: AxiosRequestConfig) {
        return CheckinApiFp(this.configuration).viewSpecs(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CheckinHotelsApi - axios parameter creator
 * @export
 */
export const CheckinHotelsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Request tries to scan all items from \'checkin_hotel_mappings\' dynamodb table
         * @summary Fetch all activated contactless checkin hotel keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAllSupportedHotels: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/hotel/supported-hotels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Request tries to get item (by primaryKey hrs_hotel_id) from \'checkin_hotel_mappings\' dynamodb table. If found = the hotel is contactless checkin supported
         * @summary Determines whether a hotel is contactless checkin supported
         * @param {string} hotelId Unique HRS hotel key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isHotelSupported: async (hotelId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hotelId' is not null or undefined
            assertParamExists('isHotelSupported', 'hotelId', hotelId)
            const localVarPath = `/hotel/{hotelId}/supported`
                .replace(`{${"hotelId"}}`, encodeURIComponent(String(hotelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CheckinHotelsApi - functional programming interface
 * @export
 */
export const CheckinHotelsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CheckinHotelsApiAxiosParamCreator(configuration)
    return {
        /**
         * Request tries to scan all items from \'checkin_hotel_mappings\' dynamodb table
         * @summary Fetch all activated contactless checkin hotel keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAllSupportedHotels(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllSupportedHotelsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAllSupportedHotels(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Request tries to get item (by primaryKey hrs_hotel_id) from \'checkin_hotel_mappings\' dynamodb table. If found = the hotel is contactless checkin supported
         * @summary Determines whether a hotel is contactless checkin supported
         * @param {string} hotelId Unique HRS hotel key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isHotelSupported(hotelId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupportedHotelResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isHotelSupported(hotelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CheckinHotelsApi - factory interface
 * @export
 */
export const CheckinHotelsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CheckinHotelsApiFp(configuration)
    return {
        /**
         * Request tries to scan all items from \'checkin_hotel_mappings\' dynamodb table
         * @summary Fetch all activated contactless checkin hotel keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAllSupportedHotels(options?: any): AxiosPromise<AllSupportedHotelsResponse> {
            return localVarFp.fetchAllSupportedHotels(options).then((request) => request(axios, basePath));
        },
        /**
         * Request tries to get item (by primaryKey hrs_hotel_id) from \'checkin_hotel_mappings\' dynamodb table. If found = the hotel is contactless checkin supported
         * @summary Determines whether a hotel is contactless checkin supported
         * @param {string} hotelId Unique HRS hotel key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isHotelSupported(hotelId: string, options?: any): AxiosPromise<SupportedHotelResult> {
            return localVarFp.isHotelSupported(hotelId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CheckinHotelsApi - object-oriented interface
 * @export
 * @class CheckinHotelsApi
 * @extends {BaseAPI}
 */
export class CheckinHotelsApi extends BaseAPI {
    /**
     * Request tries to scan all items from \'checkin_hotel_mappings\' dynamodb table
     * @summary Fetch all activated contactless checkin hotel keys
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckinHotelsApi
     */
    public fetchAllSupportedHotels(options?: AxiosRequestConfig) {
        return CheckinHotelsApiFp(this.configuration).fetchAllSupportedHotels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Request tries to get item (by primaryKey hrs_hotel_id) from \'checkin_hotel_mappings\' dynamodb table. If found = the hotel is contactless checkin supported
     * @summary Determines whether a hotel is contactless checkin supported
     * @param {string} hotelId Unique HRS hotel key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckinHotelsApi
     */
    public isHotelSupported(hotelId: string, options?: AxiosRequestConfig) {
        return CheckinHotelsApiFp(this.configuration).isHotelSupported(hotelId, options).then((request) => request(this.axios, this.basePath));
    }
}


