import axios from 'axios';

import { CheckinApiFactory } from './generated';

const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;

const instance = axios.create({
  timeout: 60000,
  headers: { 'x-client-id': '' },
});

// instance.interceptors.request.use((request) => {
//   request.headers.Authorization = localStorage.getItem('token');

//   return request;
// });

export default CheckinApiFactory(undefined, `${BASE_URL}/checkin`, instance);
