import { GetCheckinResponse, PostCheckinBody, PostCheckinResponse } from '../generated';

export abstract class CheckinAPI {
  abstract getCheckinData(params: GetCheckinParams): Promise<GetCheckinResponse>;
  abstract createCheckinOnline(body: PostCheckinBody): Promise<PostCheckinResponse>;
}

export type GetCheckinParams = {
  secureToken: string;
  language: string;
};
