import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

import { useThemeContext } from '@/contexts/ThemeContext';
import { useDisclosure } from '@/hooks/useDisclosure';

import IconArrowDownSVG from '../../../../public/static/btcimages/svgs/ic_arrow_down.svg';
import LogoSVG from '../../../../public/static/btcimages/svgs/logo.svg';
import LogoHotelDeSVG from '../../../../public/static/btcimages/svgs/logo_hotelde.svg';
import Button from '../Button';
import Typography from '../Typography';
const LanguageSelect = dynamic(() => import('../LanguageSelect'), { ssr: false });

const Navbar = function () {
  const { t, i18n } = useTranslation();
  const [opened, handleDialog] = useDisclosure(false);
  const { bSource } = useThemeContext();

  return (
    <div className='flex w-full items-center justify-between bg-primary mobile:h-20 desktop:h-[92px]'>
      <div className='m-auto flex h-full w-full max-w-7xl items-center justify-between px-3'>
        {bSource === 'hde' ? (
          <span className='relative mobile:h-12 mobile:w-12 desktop:h-[28px] desktop:w-[154px]'>
            <LogoHotelDeSVG width='100%' height='100%' />
          </span>
        ) : (
          <span className='relative mobile:h-12 mobile:w-12 desktop:top-[42px] desktop:h-[100px] desktop:w-[100px]'>
            <LogoSVG width='100%' height='100%' />
          </span>
        )}
        <Typography className='mobile:text-xs desktop:text-xl text-primary' variant='h6'>
          {t('navbar_contactless_checkIn')}
        </Typography>
        <div className='relative'>
          <Button className='self-center mobile:px-0 border-primary' variant='default' onClick={handleDialog.open}>
            <div className='flex items-center gap-2'>
              <Typography className='mobile:text-xs desktop:text-xl text-primary' variant='h6'>
                {i18n.language === 'en' ? 'EN' : 'DE'}
              </Typography>
              <span className='relative mobile:h-[22px] mobile:w-[22px] desktop:h-7 desktop:w-7'>
                <IconArrowDownSVG width='100%' height='100%' color={bSource === 'hde' ? '#121212' : '#ffffff'} />
              </span>
            </div>
          </Button>
          <LanguageSelect opened={opened} close={handleDialog.close} />
        </div>
      </div>
    </div>
  );
};
export default Navbar;
