import { ButtonStylesType } from './type';

export const variants: ButtonStylesType = {
  default: {
    border: 'border',
    borderColor: 'border-black',
    borderStyle: 'border-solid',
  },
  filled: {
    background: 'bg-btn-primary text-btn-primary',
  },
};
