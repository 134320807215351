import { GetCheckinResponse, PostCheckinBody, PostCheckinResponse } from '../generated';
import axios from '../instance';
import { CheckinAPI, GetCheckinParams } from './types';

class ApiCheckin extends CheckinAPI {
  async getCheckinData({ secureToken, language }: GetCheckinParams): Promise<GetCheckinResponse> {
    const resp = await axios.getCheckin(secureToken, language);

    return resp.data;
  }

  async createCheckinOnline(body: PostCheckinBody): Promise<PostCheckinResponse> {
    const resp = await axios.postCheckin(body);

    return resp.data;
  }
}

const apiCheckin = new ApiCheckin();

export default apiCheckin;
