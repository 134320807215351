import classnames from 'classnames';
import React from 'react';
import { twMerge } from 'tailwind-merge';

import { configTypography } from './config';
import type { AsType, ClassName, Color, Variant } from './types';

export interface TypographyProps extends React.ComponentProps<any> {
  variant: Variant;
  color?: Color;
  as?: AsType;
  className?: ClassName;
}

const Typography = ({ variant, color, as, className, children, ref, ...rest }: TypographyProps) => {
  const { variants, colors } = configTypography;

  // convert all property to string
  const typographyVariant = Object.values(variants[variant]).join(' ');
  const typographyColor = colors[color ?? 'inherit'];

  const classes = twMerge(classnames(typographyVariant, typographyColor), className);

  //set template
  let template;

  switch (variant) {
    case 'h1':
      template = React.createElement(
        as || 'h1',
        {
          ...rest,
          ref,
          className: classes,
        },
        children,
      );
      break;
    case 'h2':
      template = React.createElement(
        as || 'h2',
        {
          ...rest,
          ref,
          className: classes,
        },
        children,
      );
      break;
    case 'h3':
      template = React.createElement(
        as || 'h3',
        {
          ...rest,
          ref,
          className: classes,
        },
        children,
      );
      break;
    case 'h4':
      template = React.createElement(
        as || 'h4',
        {
          ...rest,
          ref,
          className: classes,
        },
        children,
      );
      break;
    case 'h5':
      template = React.createElement(
        as || 'h5',
        {
          ...rest,
          ref,
          className: classes,
        },
        children,
      );
      break;
    case 'h6':
      template = React.createElement(
        as || 'h6',
        {
          ...rest,
          ref,
          className: classes,
        },
        children,
      );
      break;
    case 'lead':
      template = React.createElement(
        as || 'p',
        {
          ...rest,
          ref,
          className: classes,
        },
        children,
      );
      break;
    case 'paragraph':
      template = React.createElement(
        as || 'p',
        {
          ...rest,
          ref,
          className: classes,
        },
        children,
      );
      break;
    case 'small':
      template = React.createElement(
        as || 'p',
        {
          ...rest,
          ref,
          className: classes,
        },
        children,
      );
      break;
    default:
      template = React.createElement(
        as || 'p',
        {
          ...rest,
          ref,
          className: classes,
        },
        children,
      );
      break;
  }

  return template;
};

export default Typography;
