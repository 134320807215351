import { useQuery } from 'react-query';

import apiCheckin from '@/services/checkin';
import { GetCheckinParams } from '@/services/checkin/types';
import { GetCheckinResponse } from '@/services/generated';

export const useGetCheckinData = ({ language, secureToken }: GetCheckinParams) => {
  const { error, ...query } = useQuery({
    queryKey: ['getCheckinData', secureToken, language],
    queryFn: () => apiCheckin.getCheckinData({ language, secureToken }),
    retry: false,
    enabled: !!secureToken,
    refetchOnWindowFocus: false,
  });

  if (error) {
    throw error;
  }

  return query;
};
