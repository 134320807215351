import dynamic from 'next/dynamic';
import React, { ButtonHTMLAttributes, createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';

import { useDisclosure } from '@/hooks/useDisclosure';

const Imprint = dynamic(() => import('../components/interfaces/Static/Imprint'), { ssr: false });
const PrivacyPolicy = dynamic(() => import('../components/interfaces/Static/PrivacyPolicy'), { ssr: false });
const TermsAndConditions = dynamic(() => import('../components/interfaces/Static/TermsAndConditions'), { ssr: false });
const LegalNoticeOfHotel = dynamic(() => import('../components/interfaces/CheckinForm/LegalNoticeOfHotel'), {
  ssr: false,
});

type ButtonConfigType = {
  type: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  label: string;
  onClick: () => void;
};

export type ContextType = {
  leftButtonConfig?: ButtonConfigType;
  rightButtonConfig?: ButtonConfigType;
  setLeftButtonConfig: React.Dispatch<React.SetStateAction<ButtonConfigType | undefined>>;
  setRightButtonConfig: React.Dispatch<React.SetStateAction<ButtonConfigType | undefined>>;
  openIP: () => void;
  openPP: () => void;
  openTnC: () => void;
  openLNOH: () => void;
};

export const Context = createContext<ContextType>({
  setLeftButtonConfig: () => {},
  setRightButtonConfig: () => {},
  openIP: () => {},
  openPP: () => {},
  openTnC: () => {},
  openLNOH: () => {},
});

export const FooterContext = ({ children }: PropsWithChildren) => {
  const [leftButtonConfig, setLeftButtonConfig] = useState<ButtonConfigType | undefined>();
  const [rightButtonConfig, setRightButtonConfig] = useState<ButtonConfigType | undefined>();
  const [openedIP, handleDialogIP] = useDisclosure(false);
  const [openedPP, handleDialogPP] = useDisclosure(false);
  const [openedTnC, handleDialogTnC] = useDisclosure(false);
  const [openedLNOH, handleDialogLNOH] = useDisclosure(false);

  return (
    <Context.Provider
      value={{
        leftButtonConfig,
        rightButtonConfig,
        setLeftButtonConfig,
        setRightButtonConfig,
        openIP: handleDialogIP.open,
        openPP: handleDialogPP.open,
        openTnC: handleDialogTnC.open,
        openLNOH: handleDialogLNOH.open,
      }}
    >
      {children}
      <Imprint opened={openedIP} close={handleDialogIP.close} />
      <PrivacyPolicy opened={openedPP} close={handleDialogPP.close} />
      <TermsAndConditions opened={openedTnC} close={handleDialogTnC.close} />
      <LegalNoticeOfHotel opened={openedLNOH} close={handleDialogLNOH.close} />
    </Context.Provider>
  );
};

export function useFooterContext() {
  return useContext<ContextType>(Context);
}
